<template>
<div v-loading="loading">
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :before-close="handleCloseDialog" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-form :model="formData" :rules="formRules" ref="refForm" label-position="top" :status-icon="true">
            <el-form-item :error="formErrors.newPassword" label="Insert New Password" prop="newPassword" :label-width="formLabelWidth">
                <el-input v-model="formData.newPassword" type="password" autocomplete="off" show-password></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCloseDialog()">
          Cancel
        </el-button>
            <el-button type="primary" @click="sendChildFormData()">Save</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
import {
    requiredRule,
} from "@/rules/all-rules";
export default {
    name: "AddEditDialog",
    components: {},
    props: {
        dialogVisible: {
            type: Boolean,
            default: false,
        },
        dialogType: {
            type: String,
            default: "",
        },
        formData: {
            type: Object,
            default: function () {
                return {};
            },
        },
        formErrors: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    data() {
        return {
            loading: false,
            formRules: {},
            formLabelWidth: "120px",
        };
    },
    computed: {
        dialogTitle() {
            return this.dialogType === "create" ? 'Change Password' : '';
        },
    },
    created() {
        /**
         * Including  Of Validation Rule
         */
        this.formRules = {
            newPassword: requiredRule("newPassword"),
        };
    },
    methods: {
        handleCloseDialog() {
            this.$emit("childClose");
        },
        sendChildFormData() {
            this.loading = true;
            this.$refs.refForm.validate((valid) => {
                if (valid) {
                    // WHEN EDIT MERGE NEW PARAMETERS
                    if (this.dialogType === "edit") {
                        this.formData = Object.assign(this.formData, {
                            dialog_type: this.dialogType,
                        });
                    }
                    console.log(this.formData.newPassword)
                    this.$emit("getChildFormData", this.formData.newPassword);
                    this.loading = false;
                }
            });
        },
    },
};
</script>
