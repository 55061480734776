<template>
  <div v-loading="loading" class="table-area" >
    <el-row class="table-top">
      <el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Trading Client Detail</h4>
      </el-col>
      <el-col :xs="24" :sm="4" :md="6" :lg="10" :xl="12">
        <div class="search-wrapper"></div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6" class="btn-wrapper">
        <div class="sm-8">
        <el-button @click="viewDetails" type="primary">View Details</el-button>
        <span>
          &nbsp;<el-dropdown @command="handleAction">
            <el-button type="primary" v-if="getRoleSlug !== 'superadmin'" >Action<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="update" v-if="getRoleSlug === 'admin' && this.permissions.config_edit_client" >Update</el-dropdown-item>
              <el-dropdown-item command="duplicate" v-if="(getRoleSlug === 'admin' || getRoleSlug === 'broker') && permissions.allow_create_duplicate" >Duplicate</el-dropdown-item>
              <el-dropdown-item command="changePassword" v-if = "(getRoleSlug == 'broker' && permissions.config_edit_client) || (getRoleSlug == 'admin' && permissions.allow_create_duplicate)">Change Password</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
        </div>
      </el-col>
    </el-row>

    <!-- <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="background-color:#1a2035;">
          <div class="view-btn-wrapper">
            <el-button @click="viewDetails" type="primary" style="width:100%;">View Details</el-button>
          </div>
        </el-col>
    </el-row> -->

    <!-- Personal Details -->
    <div v-if="showViewDetails">
      <el-card class="setting_wrapper">
        <div class="table_wrapper">
          <span style="color: #fff">Personal Details</span>
          <br /><br />
          <table class="table" border="1" width="100%" cellspacing="0">
            <tbody>
              <tr>
                <th style="width:200px;">
                  <p>User Name:</p>
                </th>
                <td style="width:300px;">
                  <p>{{ viewData.username }}</p>
                </td>
                <th style="width:200px;">
                  <p>Name:</p>
                </th>
                <td>
                  <p>{{ viewData.name }}</p>
                </td>
              </tr>
              <tr>
                <th style="width:200px;">
                  <p>Mobile:</p>
                </th>
                <td style="width:300px;">
                  <p>{{ viewData.mobile }}</p>
                </td>
                <th style="width:200px;">
                  <p>City:</p>
                </th>
                <td >
                  <p>{{ viewData.city }}</p>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <span style="color: #fff">Config</span>
          <br /><br />
          <table class="table" border="1" width="100%" cellspacing="0">
            <tbody>
              <tr>
                <th>
                  <p>Demo account?</p>
                </th>
                <td>
                  <p>
                    {{ config.is_demo ? "Yes" : "No" }}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Allow orders at current Bid / Ask price:</p>
                </th>
                <td>
                  <p>
                    {{
                      config.config_allow_orders_current_bid_ask_price
                        ? "Yes"
                        : "No"
                    }}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Allow Fresh Entry Order above high & below low? :</p>
                </th>
                <td>
                  <p>
                    {{
                      config.config_allow_fresh_entry_order_above_high_and_below_low
                        ? "Yes"
                        : "No"
                    }}
                  </p>
                </td>
              </tr>

              <tr>
                <th>
                  <p>One Side brokerage for intraday trades (Higher side):</p>
                </th>
                <td>
                  <p>
                    {{
                      config.config_one_side_brokerage_for_intraday_trades_Higher_side
                        ? "Yes"
                        : "No"
                    }}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>
                    Auto-close trades if losses cross beyond the configured
                    limit
                  </p>
                </th>
                <td>
                  <p>
                    {{
                      config.config_auto_close_trades_if_losses_cross_beyond_configured_limit
                        ? "Yes"
                        : "No"
                    }}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>
                    Auto-close trades if insufficient fund to hold overnight :
                  </p>
                </th>
                <td>
                  <p>
                    {{
                      config.config_auto_close_trades_if_insufficient_fund_to_hold_overnight
                        ? "Yes"
                        : "No"
                    }}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Allow Orders between High - Low? :</p>
                </th>
                <td>
                  <p>
                    {{
                      config.config_allow_orders_between_high_low ? "Yes" : "No"
                    }}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>
                    Trigger orders at New High - Low rates Instead of Bid-Ask? :
                  </p>
                </th>
                <td>
                  <p>
                    {{
                      config.config_trigger_orders_at_new_high_low_rates_instead_bid_ask
                        ? "Yes"
                        : "No"
                    }}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Trade equity as units instead of lots. :</p>
                </th>
                <td>
                  <p>
                    {{
                      config.config_trade_equity_as_units_instead_of_lots
                        ? "Yes"
                        : "No"
                    }}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Scalping not allowed :</p>
                </th>
                <td>
                  <p>
                    {{ config.config_scalping ? "Yes" : "No" }}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Account Status :</p>
                </th>
                <td>
                  <p>
                    {{ config.config_account_status ? "Yes" : "No" }}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>
                    auto-Close all active trades when the losses reach % of
                    Ledger-balance :
                  </p>
                </th>
                <td>
                  <p>
                    {{
                      config.config_auto_close_all_active_trades_when_losses_reach_percent_ledger_balance
                        ? "Yes"
                        : "No"
                    }}
                    {{
                      config.config_auto_close_all_active_trades_when_losses_reach_percent_ledger_balance_value
                    }}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>
                    Notify client when the losses reach % of Ledger-balance :
                  </p>
                </th>
                <td>
                  <p>
                    {{
                      config.config_notify_client_when_losses_reach_percent_ledger_balance
                        ? "Yes"
                        : "No"
                    }}
                    {{
                      config.config_notify_client_when_losses_reach_percent_ledger_balance_value
                    }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <span style="color: #fff">MCX Futures:</span>
          <br />
          <br />
          <table class="table" border="1" width="100%" cellspacing="0">
            <tbody>
              <tr>
                <th>
                  <p>MCX Trading:</p>
                </th>
                <td>
                  <p>{{ mcx.mcx_trading ? "Yes" : "No" }}</p>
                </td>
                <th>
                  <p>Minimum lot size required per single trade of MCX:</p>
                </th>
                <td>
                  <p>
                    {{ mcx.mcx_minimum_lot_size_per_single_trade_mcx }}
                  </p>
                </td>
              </tr>

              <tr>
                <th>
                  <p>Maximum lot size allowed per single trade of MCX:</p>
                </th>
                <td>
                  <p>
                    {{ mcx.mcx_minimum_lot_size_per_single_trade_mcx }}
                  </p>
                </td>
                <th>
                  <p>Maximum lot size allowed per single trade of MCX</p>
                </th>
                <td>
                  <p>
                    {{ mcx.mcx_maximum_lot_size_per_single_trade_mcx }}
                  </p>
                </td>
              </tr>

              <tr>
                <th>
                  <p>
                    Maximum lot size allowed per script of MCX to be actively
                    open at a time
                  </p>
                </th>
                <td>
                  <p>
                    {{
                      mcx.mcx_maximum_lot_size_per_script_mcx_actively_open_at_time
                    }}
                  </p>
                </td>
                <th>
                  <p>Max Size All Commodity:</p>
                </th>
                <td>
                  <p>
                    {{ mcx.max_size_all_commodity ? "Yes" : "No" }}
                  </p>
                </td>
              </tr>

              <tr>
                <th>
                  <p>Mcx Brokerage Type:</p>
                </th>
                <td>
                  <p>{{ mcx.mcx_brokerage_type }}</p>
                </td>
                <th>
                  <p>MCX brokerage per crore:</p>
                </th>
                <td>
                  <p>
                    {{ mcx.mcx_brokerage_per_crore }}
                  </p>
                </td>
              </tr>
              <!-- <tr>
                <th>
                  <p>Holding Exposure/Margin MCX:</p>
                </th>
                <td>
                  <p>
                    {{ mcx.mcx_holding_exposure_margin_mcx }}
                  </p>
                </td>
              </tr> -->
              <br />
              <p style="color: #fff; margin-left: 12px;">MCX Exposure Lot wise</p>
              <br />
              <!-- {{mcx}} -->
                  <table v-if="mcx.mcx_brokerage_type == 'per_lot_basis'">
                    <tbody>
                      <tr v-for="(mcx, index) in mcx.mcx_brokerage_lot_wise" :key="index">
                        <th>
                          <p>{{mcx.stock_id}}</p>
                        </th>
                        <td>
                          <p>{{mcx.value}}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                 <br />


              <tr>
                <th>
                  <p>Exposure Mcx Type:</p>
                </th>
                <td>
                  <p>{{ mcx.mcx_exposure_mcx_type }}</p>
                </td>
                <th>
                  <p>Intraday Exposure/Margin MCX:</p>
                </th>
                <td>
                  <p>{{ mcx.mcx_intraday_exposure_margin_mcx }}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Holding Exposure/Margin MCX:</p>
                </th>
                <td>
                  <p>{{ mcx.mcx_holding_exposure_margin_mcx }}</p>
                </td>

              </tr>

              <br />
            </tbody>
          </table>

          <br />
          <span style="color: #fff">Equity Futures:</span>
          <br />
          <br />

          <table>
            <tbody>
              <tr>
                <th>
                  <p>Equity Trading:</p>
                </th>
                <td>
                  <p>{{ equity.equity_trading ? "Yes" : "NO" }}</p>
                </td>
                <th>
                  <p>
                    Minimum lot size required per single trade of Equity INDEX:
                  </p>
                </th>
                <td>
                  <p>
                    {{
                      equity.equity_minimum_lot_size_per_single_trade_equity_index
                    }}
                  </p>
                </td>
                 <!-- <th>
                  <p>Minimum lot size required per single trade of Equity:</p>
                </th>
                <td>
                  <p>
                    {{ equity.equity_minimum_lot_size_per_single_trade_equity }}
                  </p>
                </td> -->
                <!-- <th>
                  <p>Equity Brokerage Type:</p>
                </th>
                <td>
                  <p>
                    {{
                      equity.equity_brokerage_type == "per_core_basics"
                        ? "Per Core Basics"
                        : "Per Lot Basics"
                    }}
                  </p>
                </td> -->
              </tr>
              <!-- <tr>
                <th>
                  <p>Equity Brokerage:</p>
                </th>
                <td>
                  <p>{{ equity.equity_brokerage }}</p>
                </td>
                <th>
                  <p>Minimum lot size required per single trade of Equity:</p>
                </th>
                <td>
                  <p>
                    {{ equity.equity_minimum_lot_size_per_single_trade_equity }}
                  </p>
                </td>
              </tr> -->

              <!-- <tr> -->
                <!-- <th>
                  <p>Maximum lot size allowed per single trade of Equity:</p>
                </th>
                <td>
                  <p>
                    {{ equity.equity_maximum_lot_size_per_single_trade_equity }}
                  </p>
                </td> -->

              <!-- </tr> -->
              <tr>
                <th>
                  <p>
                    Maximum lot size allowed per single trade of Equity INDEX:
                  </p>
                </th>
                <td>
                  <p>
                    {{
                      equity.equity_maximum_lot_size_per_single_trade_equity_index
                    }}
                  </p>
                </td>
                <th>
                  <p>
                    Maximum lot size allowed per script of Equity INDEX to be actively open at a time :
                  </p>
                </th>
                <td>
                  <p>
                    {{
                      equity.equity_maximum_lot_size_per_script_equity_index_actively_open_at_time
                    }}
                  </p>
                </td>
              </tr>
              <tr>
                <!-- <th>
                  <p>
                    Maximum lot size allowed per script of Equity INDEX to be
                    actively open at a time:
                  </p>
                </th>
                <td>
                  <p>
                    {{
                      equity.equity_maximum_lot_size_per_script_equity_index_actively_open_at_time
                    }}
                  </p>
                </td> -->
                <th>
                  <p>Max Size All Equity:</p>
                </th>
                <td>
                  <p>
                    {{ equity.equity_max_size_all_equity }}
                  </p>
                </td>
                <th>
                  <p>Exposure Equity Type:</p>
                </th>
                <td>
                  <p>
                    {{ equity.equity_exposure_equity_type }}
                  </p>
                </td>
              </tr>
              <!-- <tr>
                <th>
                  <p>Max Size All Index:</p>
                </th>
                <td>
                  <p>
                    {{ equity.equity_max_size_all_index }}
                  </p>
                </td>
                <th>
                  <p>Orders to be away by % from current price Equity:</p>
                </th>
                <td>
                  <p>
                    {{
                      equity.equity_orders_away_percent_from_current_price_equity
                    }}
                  </p>
                </td>
              </tr> -->
              <tr>
                <th>
                  <p>Intraday Exposure/Margin Equity:</p>
                </th>
                <td>
                  <p>
                    {{ equity.equity_intraday_exposure_margin_equity }}
                  </p>
                </td>
                 <th>
                  <p>Holding Exposure/Margin Equity:</p>
                </th>
                <td>
                  <p>
                    {{ equity.equity_holding_exposure_margin_equity }}
                  </p>
                </td>
              </tr>

              <tr>

              </tr>
              <!-- <tr>
                <th>
                  <p>Orders to be away by % from current price Equity:</p>
                </th>
                <td>
                  <p>
                    {{
                      equity.equity_orders_away_by_percent_from_current_price_equity
                    }}
                  </p>
                </td>
              </tr> -->
            </tbody>
          </table>
        </div>
      </el-card>
    </div>
 

    <!-- Fund Details -->
    <div>
      <el-card class="setting_wrapper">
        <div class="table_wrapper">
          <span style="color: #fff">Fund Details</span>
          <br /><br />
          <table class="table" border="1" width="100%" cellspacing="0">
            <tbody>
              <tr>
                <th>
                  <p>Amount:</p>
                </th>
                <th>
                  <p>Created At:</p>
                </th>
                <th>
                  <p>Fund Type:</p>
                </th>
                <th>
                  <p>Notes:</p>
                </th>
              </tr>
              <tr v-for="(fund, index) in viewData.funds" :key="index">
                <td>
                  <p>{{ fund.amount }}</p>
                </td>
                <td>
                  <p>{{ getFormatedDate(fund.createdAt) }}</p>
                </td>
                <td>
                  <p>{{ fund.fund_type }}</p>
                </td>
                <td>
                  <p>{{ fund.notes }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-card>
    </div>
    <br>

    <!-- Active Trade -->
    <div>
      <el-card class="setting_wrapper">
        <div class="table_wrapper">
          <span style="color: #fff">Active Trade</span>
          <br /><br />
          <table class="table" border="1" width="100%" cellspacing="0">
            <tbody>
              <tr>
                <th v-if="getRoleSlug != 'broker'">
                  <p></p>
                </th>
                <th>
                  <p>Id:</p>
                </th>
                <th>
                  <p>Script:</p>
                </th>
                <th>
                  <p>Type:</p>
                </th>
                <th>
                  <p>Lots:</p>
                </th>
                <!-- <th>
                  <p>Margin:</p>
                </th> -->
                <th>
                  <p>Entry Price:</p>
                </th>
                <th>
                  <p>Cmp:</p>
                </th>
                <th>
                  <p>P/L:</p>
                </th>
                <th>
                  <p>Created At:</p>
                </th>
              </tr>
              <tr
                v-for="(activeTrade, index) in viewData.activeTrades"
                :key="index"
              >
                <td v-if="getRoleSlug != 'broker'">
                  <p style="cursor: pointer" @click="handelCloseTrade(activeTrade.id)">X</p>
                </td>
                <td>
                  <p>{{ activeTrade.unique_id }}</p>
                </td>
                <td>
                  <p>{{ activeTrade.script }}</p>
                </td>
                <td>
                  <p>{{ activeTrade.type }}</p>
                </td>
                <td>
                  <p>{{ activeTrade.unit }} ({{ activeTrade.lot }})</p>
                </td>
                <!-- <td>
                  <p>{{ activeTrade.margin }}</p>
                </td> -->
                <td>
                  <p>{{ activeTrade.avg_price }}</p>
                </td>
                <td>
                  <p>{{ activeTrade.cmp }}</p>
                </td>
                <td>
                  <p>{{ activeTrade.stock_pl }}</p>
                </td>
                <td>
                  <p>{{ activeTrade.createdAt }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-card>
    </div>
     <br>

    <!-- Close Trade -->
    <div v-if="viewDataCloseTrades">
      <el-card class="setting_wrapper">
        <div class="table_wrapper">
          <span style="color: #fff">Close Trade</span>
          <br /><br />
          <table class="table" border="1" width="100%" cellspacing="0">
            <tbody>
              <tr>
                <th>
                  <p>Id:</p>
                </th>
                <th>
                  <p>Script:</p>
                </th>
                <th>
                  <p>Lots:</p>
                </th>
                <th>
                  <p>Avg Buy Rate:</p>
                </th>
                <th>
                  <p>Avg Sell Rate:</p>
                </th>
                <th>
                  <p>Profite/Loss:</p>
                </th>
                <th>
                  <p>Brokerage:</p>
                </th>
                <th>
                  <p>Brought At:</p>
                </th>
                <th>
                  <p>Sold At:</p>
                </th>
              </tr>
              <tr
                v-for="(activeTrade, index) in viewDataCloseTrades"
                :key="index"
              >
                <td>
                  <p>{{ activeTrade.unique_id }}</p>
                </td>
                   <td>
                  <p>{{ activeTrade.script }}</p>
                </td>
                <td>
                  <p>{{ activeTrade.unit }} ({{ activeTrade.lot }})</p>
                </td>
                <td>
                  <p>{{ activeTrade.bought_price }}</p>
                </td>
                <td>
                  <p>{{ activeTrade.sold_price }}</p>
                </td>
                <td>
                  <p>{{ activeTrade.pl }}</p>
                </td>
                <td>
                  <p>{{ activeTrade.broking_charge }}</p>
                </td>
                <td>
                  <p>{{ activeTrade.bought_date }}</p>
                </td>
                <td>
                  <p>{{ activeTrade.sold_date }}</p>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- // PAGINATION -->
          
        </div>
        <div class="table-wrapper" style="margin-top: 1px">
            <el-pagination
              v-if="tableDataTotalCloseTrades"
              :total="tableDataTotalCloseTrades"
              :current-page.sync="listQueryCloseTrades.page"
              :page-size.sync="listQueryCloseTrades.limit"
              layout="prev, pager, next"
              @size-change="handlePaginationCloseTrades()"
              @current-change="handlePaginationCloseTrades()"
            />
          </div>
      </el-card>
    </div>
     <br>

    <!-- MCX Pending Orders -->
    <div>
      <el-card class="setting_wrapper">
        <div class="table_wrapper">
          <span style="color: #fff">MCX Pending Orders</span>
          <br /><br />
          <table class="table" border="1" width="100%" cellspacing="0">
            <tbody>
              <tr>
                <th>
                  <p>Id</p>
                </th>
                <th>
                  <p>Lots</p>
                </th>
                <th>
                  <p>Commodity</p>
                </th>
                <th>
                  <p>Type</p>
                </th>
                <th>
                  <p>Rate</p>
                </th>
                <th>
                  <p>Date</p>
                </th>
              </tr>
              <tr v-for="(data, index) in viewData.mcxPendingOrders" :key="index">
                <td>
                  <p>{{ data.unique_id }}</p>
                </td>
                <td>
                  <p>{{ data.unit }} ({{ data.lot }})</p>
                </td>
                <td>
                  <p> {{ data.stock_name }}</p>
                </td>
                <td>
                  <p> {{ data.order_type}}</p>
                </td>
                <td>
                  <p>{{ data.price }}</p>
                </td>
                <td>
                  <!-- <p>{{ getFormatedDate(data.createdAt) }}</p> -->
                  <p>{{ data.createdAt }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-card>
    </div>
     <br>

    <!-- Equity Pending Orders -->
    <div>
      <el-card class="setting_wrapper">
        <div class="table_wrapper">
          <span style="color: #fff">Equity Pending Orders</span>
          <br /><br />
          <table class="table" border="1" width="100%" cellspacing="0">
            <tbody>
              <tr>
                <th>
                  <p>Id</p>
                </th>
                <th>
                  <p>Lots</p>
                </th>
                <th>
                  <p>Commodity</p>
                </th>
                <th>
                  <p>Condition</p>
                </th>
                <th>
                  <p>Rate</p>
                </th>
                <th>
                  <p>Date</p>
                </th>
              </tr>
              <tr v-for="(data, index) in viewData.equityPendingOrders" :key="index">
                <td>
                  <p>{{ data.unique_id }}</p>
                </td>
                <td>
                  <p>{{ data.unit }} ({{ data.lot }})</p>
                </td>
                <td>
                  <p>{{ data.stock_name }}</p>
                </td>
                <td>
                  <p>{{ data.order_type}}</p>
                </td>
                <td>
                  <p>{{ data.price }}</p>
                </td>
                <td>
                  <p>{{ data.createdAt }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-card>
    </div>
     <br>
    <!-- {{userInfo}} -->
    <!-- Backup Storage -->
    <div>
      <el-card class="setting_wrapper">
        <div class="table_wrapper">
          <span style="color: #fff">Backup Storage</span>
          <br /><br />
          <table class="table" border="1" width="100%" cellspacing="0">
            <tbody>
              <tr>
                <th>
                  <p>Filename</p>
                </th>
                <th>
                  <p>Created at</p>
                </th>
                <th>
                  <p>Download</p>
                </th>

              </tr>
                <tr v-for="(data, index) in viewData.backup_storage" :key="index">
                  <td>
                    <p>{{data.title}}</p>
                  </td>
                  <td>
                    <p>{{data.bill_generate_date}}</p>
                  </td>
                  <td style="cursor: pointer" @click="downloadBackupStorage(data,index)">
                    
                    <p v-if="downloadingBackupStorage !== index">Download</p>
                  <p v-if="downloadingBackupStorage === index">Downloading...</p>
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
      </el-card>
    </div>
     <br>

    <!-- Weekly Bills Auto-Generated -->
    <div>
      <el-card class="setting_wrapper">
        <div class="table_wrapper">
          <span style="color: #fff">Weekly Bills Auto-Generated</span>
          <br /><br />
          <table class="table" border="1" width="100%" cellspacing="0">
            <tbody>
              <tr>
                <th>
                  <p>Filename</p>
                </th>
                <th>
                  <p>Created at</p>
                </th>
                <th>
                  <p>Download</p>
                </th>

              </tr>
               <tr v-for="(data, index) in viewData.weekly_bills_generated" :key="index">
                <td>
                  <p>Bill</p>
                </td>
                <td>
                  <!-- <p>{{data.created_at}}</p> -->
                  <p>{{data.bill_generate_date}}</p>
                </td>
                <td style="cursor: pointer" @click="downloadWeeklyBillsGenerated(data,index)">
                  <p v-if="downloadingWeeklyBill !== index">Download</p>
                  <p v-if="downloadingWeeklyBill === index">Downloading...</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-card>
    </div>

    <PasswordEditDialog
      :dialog-visible.sync="dialogVisiblePasswordEdit"
      :dialog-type="dialogTypePasswordEdit"
      :form-data="formData"
      :form-errors.sync="formErrors"
      @getChildFormData="handleUpdatePassword($event)"
      @childClose="handleChildClose()"
    />

    <DuplicateAccountDialog
      :dialog-visible.sync="dialogVisibleDuplicateAccount"
      :dialog-type="dialogTypeDuplicateAccount"
      :form-data="formData"
      :form-errors.sync="formErrors"
      @getChildFormData="handleDuplicateAccount($event)"
      @childClose="handleDuplicateAccountClose()"
    />

  </div>
</template>

<script>
import { showClientDetails, refreshAccount, duplicateAccount, passwordUpdate, downloadBackupStorage, downloadWeeklyBillsGenerated, fetchListCloseTrades } from "@/api/trading-client";
import getRoleSlug from "@/store/app.js";
import PasswordEditDialog from "@/views/trading-clients/components/PasswordEditDialog.vue";
import DuplicateAccountDialog from "@/views/trading-clients/components/DuplicateAccountDialog.vue";
import { mapGetters } from "vuex";
import { clientCloseActiveTrade } from "@/api/market-watch";


export default {
  name: "TradingClientDetails",
  components: {
    PasswordEditDialog,
    DuplicateAccountDialog
  },
  data() {
    return {
      loading: false,
      downloadingWeeklyBill:false,
      downloadingBackupStorage:false,
      viewData: {},
      viewDataCloseTrades: {},
      config: {},
      equity: {},
      mcx: {},
      showViewDetails: false,
      delectval: null,
      dialogVisiblePasswordEdit: false,
      dialogTypePasswordEdit: "create",
      dialogVisibleDuplicateAccount: false,
      dialogTypeDuplicateAccount: "create",
      formData: {},
      formErrors: [],
      permission: false,

      tableDataTotalCloseTrades: 0,
      listQueryCloseTrades: {
        page: 1,
        limit: 20,
        search: '',
        orderBy: 'created_at',
        sortedBy: 'desc'
      },
    };
  },
  computed: {
    ...mapGetters(["getRoleSlug", "userInfo", "permissions" ]),
  },
  created() {
    this.id = this.$route.params.id;
    this.userId = this.$route.params.id;
    this.listQueryCloseTrades.id = this.id;
    console.log(this.$route.params.id);
    this.getPendingOrderDetails();
    this.handlePaginationCloseTrades();
  },
  methods: {
      handleCommand(command) {
        this.$message('click on item ' + command);
      },
    handelCloseTrade(id) {
      this.$confirm('Are you sure?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        clientCloseActiveTrade(id)
              .then((response) => {
                if (response.data.success === true) {
                  this.flashSuccess(response.data.message);
                  this.getPendingOrderDetails();
                  this.loading = false;
                } else {
                  this.flashError(response.data.message);
                  this.formErrors = this.validationErrors(response.data.message);
                }
                this.loading = false;
              })
              .catch((err) => {
                console.log(err);
              });
      })
    },
    downloadBackupStorage(data, index) {
    
      this.downloadingBackupStorage = index;
      //console.log("DDDD ggg    ", this.downloadingBackupStorage)
      let dataPost = {
        date_from: data.date_from,
        date_to: data.date_to,
        type: data.type,
        id: this.$route.params.id
      }
      downloadBackupStorage(dataPost).then((response)=> {
        console.log(response.data.data)
        let res = response.data.data
        this.download(res.fileName,res.path)
        this.downloadingBackupStorage = false;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    downloadWeeklyBillsGenerated(data,index) {
      console.log("DDDD    ", data)
      this.downloadingWeeklyBill = index;
      console.log("DDDD    ", this.downloadingWeeklyBill)
      let dataPost = {
        date_from: data.date_from,
        date_to: data.date_to,
        type: data.type,
        id: this.$route.params.id
      }
      downloadWeeklyBillsGenerated(dataPost).then((response)=> {
        console.log(response.data.data)
        let res = response.data.data
        this.download(res.fileName,res.path)
        this.downloadingWeeklyBill = false;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    download(filename, url) {
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          // xhr.setRequestHeader('Content-type','application/x-www-form-urlencoded');
          xhr.onload = () => {
              var blob = xhr.response;
              var downloaded = document.createElement('a');
              var downloadedurl = window.URL.createObjectURL(blob);
              downloaded.href = downloadedurl;
              downloaded.download = filename;
              document.body.append(downloaded);
              downloaded.click();
              downloaded.remove();
              window.URL.revokeObjectURL(downloadedurl);
          };
          xhr.open('GET', url);
          xhr.send();
    },
    viewDetails() {
      this.showViewDetails = !this.showViewDetails
    },
    handleAction(value) {
       console.log(value)
       if(value == 'update') {
        this.handleEdit();
       } else if(value == 'refreshAccount') {
        this.refreshAccount()
       } else if (value == 'duplicate') {
        this.duplicateAccount()
       } else if (value == 'changePassword') {
        this.changePassword()
       }
    },
    handleEdit() {
        return this.$router.push(
          "/" + getRoleSlug.state.roleSlug + "/trading-clients/trading-clients-edit/" + this.$route.params.id
        );
    },
    refreshAccount() {
      console.log(this.$route.params.id);
      this.$confirm(
        "Are you sure you want to Reset this item? All History will be cleared. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        let dataPost = {
          id: this.$route.params.id
        }
        refreshAccount(dataPost).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            // this.getList();
            // location.reload()
            this.loading = false;
          }
        });
      });
    },
    duplicateAccount() {
      if (this.userInfo.allowedclient > this.userInfo.customerCount  || this.getRoleSlug == "broker") {
        this.permission = true;
      }
      if (this.permission) {
        this.dialogVisibleDuplicateAccount = true;
        this.dialogTypeDuplicateAccount = "create";
      } else {
        this.$notify.error({
          title: "Error",
          message: "Limit Cross",
        });
      }


    },
    handleDuplicateAccount(data) {
      console.log(data);
       this.loading = true;
       let dataPost = {
          id : this.$route.params.id,
          username : data.username,
          name: data.name,
          password : data.password,
       }
        duplicateAccount(dataPost).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.dialogVisibleDuplicateAccount = false;
            console.log(response.data)
            this.$router.push("/" + getRoleSlug.state.roleSlug + "/trading-clients/trading-clients-view/" + response.data.data);
            location.reload()
            this.loading = false;
          } else {
            this.flashError("Username already exist");
          }
        });
    },
    handleDuplicateAccountClose() {
      this.dialogVisibleDuplicateAccount = false;
      this.delectval = null;
    },
    changePassword() {
      this.formData = {};
      this.formErrors = [];
      this.dialogVisiblePasswordEdit = true;
      this.dialogTypePasswordEdit = "create";
      this.delectval = null;
    },
    handleChildClose() {
      this.dialogVisiblePasswordEdit = false;
      this.delectval = null;
    },
    handleUpdatePassword(newPassword) {
      console.log(newPassword);
      let dataPost = {
        id : this.$route.params.id,
        new_password : newPassword
      }
      console.log(dataPost);
      passwordUpdate(dataPost)
        .then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.loading = false;
            this.dialogVisiblePasswordEdit = false;
            //location.reload()
          } else {
            this.formErrors = this.validationErrors(
              response.data.message
            );
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });

    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getPendingOrderDetails();
    },
    handleSearch() {
      this.listQuery.page = 1;
      this.getPendingOrderDetails();
    },
    getPendingOrderDetails() {
      this.loading = true;
      showClientDetails(this.$route.params.id).then((response) => {
        this.viewData = response.data.data;
        this.config = response.data.data.config;
        this.equity = response.data.data.equity;
        this.mcx = response.data.data.mcx;
        console.log("respos  ",this.viewData);
        console.log(this.config);
        this.loading = false;
      });
    },

    // //////////////////
    // GET LIST DATA - CLOSE TRADES
    // //////////////////
    handlePaginationCloseTrades() {
      this.loading = true;
      fetchListCloseTrades(this.listQueryCloseTrades).then((response) => {
        this.viewDataCloseTrades = response.data.data.data;
        this.tableDataTotalCloseTrades = response.data.data.meta.total;
        this.loading = false;
      });
    },
  },
};
</script>
